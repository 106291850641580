.Subtree-line {
    display: grid;
    grid-template-columns: 20px 40px 1fr;
    align-items: center;
    font-size: 16px;
    border-bottom: 1px solid var(--bs-gray-100);
    cursor: pointer;
    padding: 0 2px;

    &:hover {
        background-color: var(--bs-gray-100);
    }

    &.hidden {
        display: none;
    }

    .Subtree-toggle-symbol {
        font-size: 12px;
    }

    .Subtree-file-symbol {
        font-size: 24px;
    }

    .Subtree-name {
        text-align: left;
    }
}

.Subtree-dir {
    &.hidden {
        display: none;
    }
}