.navbar {
    --logo-height: 48px;
    color: white;
    .navbar-brand {
        padding-top: 0;
        padding-bottom: 0;
        img {
            height: var(--logo-height);
        }

    }

    i {
        font-size: 24px;
        color: var(--bs-white);
    }
    i:hover {
        color: var(--bs-gray-200);
    }

    .navbar-settings {
        .dropdown-toggle::before {
            display: none;
        }


    }
}